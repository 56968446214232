<template>
  <section class="faq content-wrapper">
    <div class="page-header">
      <h3 class="page-title">
        FAQ
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">FAQ</li>
        </ol>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="faq-section">
              <div class="container-fluid bg-primary py-2">
                <p class="mb-0 text-white">Section 1</p>
              </div>
              <div role="tablist" class="accordion">
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <a href="javascript:void(0);" v-b-toggle.accordion1>How many instances of Lorum Ipsum usage can be found on the web?</a>
                  </b-card-header>
                  <b-collapse id="accordion1" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-md-3">
                          <img class="img-fluid rounded" src="https://placehold.it/200x150" alt="image"> </div>
                        <div class="col-md-9">
                          <p>You can pay for the product you have purchased using credit cards, debit cards, or via online banking. </p>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <a href="javascript:void(0);" v-b-toggle.accordion2>What is Lorum Ipsum and who invented it?</a>
                  </b-card-header>
                  <b-collapse id="accordion2" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                        aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <a href="javascript:void(0);" v-b-toggle.accordion3>
                      What should I do if I need any assistance using my account
                    </a>
                  </b-card-header>
                  <b-collapse id="accordion3" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p>If you need any assistance while using your account, you can contact our customer support via email at support@abc.com. Or you can live chat with our support team. Our support team is available 24*7</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
            <div class="faq-section mt-4">
              <div class="container-fluid bg-primary py-2">
                <p class="mb-0 text-white">Section 2</p>
              </div>
              <div role="tablist" class="accordion">
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <a href="javascript:void(0);" v-b-toggle.accordion4>How many instances of Lorum Ipsum usage can be found on the web?</a>
                  </b-card-header>
                  <b-collapse id="accordion4" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-md-3">
                          <img class="img-fluid rounded" src="https://placehold.it/200x150" alt="image"> </div>
                        <div class="col-md-9">
                          <p>You can pay for the product you have purchased using credit cards, debit cards, or via online banking. </p>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <a href="javascript:void(0);" v-b-toggle.accordion5>What is Lorum Ipsum and who invented it?</a>
                  </b-card-header>
                  <b-collapse id="accordion5" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                        aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <a href="javascript:void(0);" v-b-toggle.accordion6>
                      What should I do if I need any assistance using my account
                    </a>
                  </b-card-header>
                  <b-collapse id="accordion6" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p>If you need any assistance while using your account, you can contact our customer support via email at support@abc.com. Or you can live chat with our support team. Our support team is available 24*7</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
            <div class="faq-section mt-4">
              <div class="container-fluid bg-primary py-2">
                <p class="mb-0 text-white">Section 3</p>
              </div>
              <div role="tablist" class="accordion">
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <a href="javascript:void(0);" v-b-toggle.accordion7>How many instances of Lorum Ipsum usage can be found on the web?</a>
                  </b-card-header>
                  <b-collapse id="accordion7" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <div class="row">
                        <div class="col-md-3">
                          <img class="img-fluid rounded" src="https://placehold.it/200x150" alt="image"> </div>
                        <div class="col-md-9">
                          <p>You can pay for the product you have purchased using credit cards, debit cards, or via online banking. </p>
                        </div>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <a href="javascript:void(0);" v-b-toggle.accordion8>What is Lorum Ipsum and who invented it?</a>
                  </b-card-header>
                  <b-collapse id="accordion8" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt
                        aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo. Leggings occaecat
                        craft beer farm-to-table, raw denim aesthetic synth nesciunt you probably haven't heard of them accusamus labore sustainable VHS.
                    </b-card-body>
                  </b-collapse>
                </b-card>
                <b-card no-body>
                  <b-card-header header-tag="header" role="tab">
                    <a href="javascript:void(0);" v-b-toggle.accordion9>
                      What should I do if I need any assistance using my account
                    </a>
                  </b-card-header>
                  <b-collapse id="accordion9" accordion="my-accordion" role="tabpanel">
                    <b-card-body>
                      <p>If you need any assistance while using your account, you can contact our customer support via email at support@abc.com. Or you can live chat with our support team. Our support team is available 24*7</p>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'faq'
}
</script>